
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,500;1,400&display=swap');



.container {
  position: relative;
  // padding: 20px;
  height: 100dvh;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'Roboto Mono', monospace;

  .bg-top {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    object-fit: cover;
  }
  .bg-top-sec {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .bg-bot {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .bg-bot-sec {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .inner-content {
    // border-style: solid;
    // border-color: #fff;
    // border-radius: 10px;
    // border-width: 1px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .google-logo {
      display: flex;
      justify-content: center;
      padding-top: 10vh;
      position: absolute;

      img {
        width: 60%;
      }
    }

    .box {
      position: absolute;
      top: 45vh;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto Mono', monospace;
      width: 250px;
      background-color: #FFF;
      padding: 50px 0;
      box-shadow: #79c257 4px 4px 0 0;
      color: #33A852;
      

      img {
        width: 250px;
      }

      .text {
        // position: absolute;
        font-size: 34px;
        font-weight: bold;
        top: 30%;
        letter-spacing: 6px;
      }

      .sub-text {
        position: absolute;
        top: 70%;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .error {
      color: red;
      position: absolute;
      top: 340px;
      z-index: 1000;
    }

    .button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Roboto Mono', monospace;
     
      .button-74 {
        background-color: #FFF;
        border: 2px solid #FFF;
        box-shadow: #79c257 4px 4px 0 0;
        color: #33A852;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        padding: 0 35px;
        line-height: 50px;
        text-align: center;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .button-74:hover {
        background-color: #fff;
      }

      .button-74:active {
        box-shadow: #422800 2px 2px 0 0;
        transform: translate(2px, 2px);
      }

      @media (min-width: 768px) {
        .button-74 {
          min-width: 120px;
          padding: 0 25px;
        }
      }
    
    
    }

    .google-pic {
      position: absolute;
      z-index: -100;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 80%;



      .background {
        width: 100%;
        object-fit: cover;
      }

      .sec-bg {
        position: absolute;
        width: 100%;
      }

      .bg-code {
        position: absolute;
        width: 100%;
      }
    }


    //section display code here! 

    .logo-code {
      position: absolute;
      left: 40px;
      top: 40px;

      img {
        width: 100px;
      }
    }

    .bg-code {
      position: absolute;
      z-index: -100;
      left: 0;
      top: 25vh;

      img {
        width: 100%;
      }
    }

    .box-code {
      position: absolute;
      top: 30vh;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      background-color: #FFF;
      padding: 70px 0px;
      box-shadow: #78C257 4px 4px 0 0;

      

      .text-code {
        position: absolute;
        display: flex;
        flex-direction: row;
        font-size: 54px;
        font-family: 'Roboto Mono', monospace;
        color: #33A852;
      }

      .sub-text {
        font-family: 'Roboto Mono', monospace;
        position: absolute;
        top: 10%;
        left: 8%;
        font-size: 12px;
        font-weight: bold;
      }

      .error {
        position: absolute;
        top: 80%;
      }

      .box-input {
        font-weight: bold;
        position: absolute;
        text-align: center;
        color: #38a852;
        border: none;
        outline: none;
        background: none;
        padding: 0;
        margin: 0;
        font-size: 32px;
        padding-bottom: 3px;
        font-family: 'Roboto', sans-serif;
      }

      .button-code {
        position: absolute;
        top: 170px;

        .button-74 {
          background-color: #FFF;
          border: 2px solid #FFF;
          box-shadow: #78C257 4px 4px 0 0;
          color: #33A852;
          cursor: pointer;
          display: inline-block;
          font-weight: 600;
          font-size: 18px;
          padding: 0 35px;
          line-height: 50px;
          text-align: center;
          text-decoration: none;
          user-select: none;
          -webkit-user-select: none;
          touch-action: manipulation;
        }
  
        .button-74:hover {
          background-color: #fff;
        }
  
        .button-74:active {
          box-shadow: #422800 2px 2px 0 0;
          transform: translate(2px, 2px);
        }
  
        @media (min-width: 768px) {
          .button-74 {
            min-width: 120px;
            padding: 0 25px;
          }
        }  
      }
    }

    // section throw page 

    .error-page {
      color: #fbeee0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .oops {
        display: flex;
        flex-direction: row;
        font-size: 60px;
        font-family: 'Roboto Mono', monospace;
        position: absolute;
        top: 40vh;

        .t-1 {
          color: #428EFF;
        }
        .t-2 {
          color: #FF5145;
        }
        .t-3 {
          color: #38a852;
        }
        .t-4 {
          color: #FFBB01;
        }
      }

      .text {
        position: absolute;
        width: 244px;
        top: 48vh;
      }

      .loading {
        position: absolute;
        top: 40vh;

        .loader {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          border: 10px solid;
          box-sizing: border-box;
          animation: animloader 1s linear infinite alternate;
        }
        @keyframes animloader {
          0% {
            border-color: #428EFF rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
          }
          33% {
            border-color: #428EFF #FF5145 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
          }
          66% {
            border-color: #428EFF #FF5145 #38a852 rgba(255, 255, 255, 0);
          }
          100% {
            border-color: #428EFF #FF5145 #38a852 #FFBB01;
          }
        } 
      }

    }
  }



}